import React from "react";
import { renderRichText } from "twill-contentful-text-render";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import { Section } from "site-ui/src/components/Section";
import { SubcategoryQuestionList } from "site-ui/src/components/SubcategoryQuestionList";
import { LocaleLink as Link } from "gatsby-theme-twill-website";
import { PageWrapper } from "site-ui/src/components/PageWrapper";

const LinkRenderer = ({ to, children }) => (
  <Link to={`../${to}/`}>{children}</Link>
);

const Question = ({ data, pageContext }) => {
  const {
    contentfulQuestion: {
      question: { question },
      answer,
      category: subCategory,
      videoEmbed,
    },
  } = data;

  const { category } = pageContext;

  return (
    <PageWrapper>
      <Breadcrumbs category={category} question={question} />
      <Header headline={category.name} theme={"grey"} />
      <Section>
        <Wrapper>
          <h2>{question}</h2>
          <div>{renderRichText(answer)}</div>
          {videoEmbed && videoEmbed.embedUrl && (
            <StyledVideoIframe src={videoEmbed.embedUrl} frameBorder="0" />
          )}
          <SubcategoryQuestionList
            borderTop={true}
            hideFrom={10}
            style={{ marginTop: 120 }}
            subCategory={subCategory}
            Link={LinkRenderer}
          />
        </Wrapper>
      </Section>
    </PageWrapper>
  );
};

const Wrapper = styled.article`
  margin: 0 auto;
  max-width: 980px;
`;

const StyledVideoIframe = styled.iframe`
  display: block;
  margin: 80px 0 0;
  max-width: 50rem;
  aspect-ratio: 16 / 9;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 80%;
  }
`;

export default Question;

export const pageQuery = graphql`
  query questionQuery($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulQuestion(id: { eq: $id }) {
      question {
        question
      }
      seoMeta {
        slug
      }
      answer {
        raw
      }
      videoEmbed {
        embedUrl
      }
      category {
        name
        question {
          question {
            question
          }
          seoMeta {
            title
            slug
            description
          }
        }
      }
    }
  }
`;
